<template>
  <div id="card" class="container">
    <div class="d-flex">
      <h4 class="card-title mr-auto m-0">{{ name }}</h4>
      <span class="arrow-down-icon" :class="{ arrowup: isToggled }" @click="toggle()">
        <img src="../assets/svgs/arrow-up.svg" />
      </span>
    </div>
    <!-- / card Header -->
    <div id="add-request" v-if="!isToggled">
      <b-button variant="btn btn-primary" @click="add()">{{ i18n['prayer-request'].tcAddPrayerRequest }}</b-button>
    </div>
    <div class="card-table" v-if="!isToggled">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">
                {{ i18n['prayer-request'].tcType }}<b-icon-arrow-down scale="1.2" shift-h="3"></b-icon-arrow-down>
              </th>
              <th scope="col">
                {{ i18n['prayer-request'].tcStartDate }}<b-icon-arrow-down scale="1.2" shift-h="3"></b-icon-arrow-down>
              </th>
              <th scope="col">
                {{ i18n['prayer-request'].tcEndDate }}<b-icon-arrow-down scale="1.2" shift-h="3"></b-icon-arrow-down>
              </th>
              <th></th>
              <th scope="col">{{ i18n['prayer-request'].tcEditDelete }}</th>
            </tr>
          </thead>
          <tbody class="card-row" v-for="(item, index) in itemValues" :key="index + 'item'">
            <tr>
              <th scope="row">{{ item.cid_request_title }}</th>
              <td>{{ showDate(item.cid_start_date) }}</td>
              <td>{{ showDate(item.cid_end_date) }}</td>
              <td>
                <a @click="open(item)">{{ i18n['prayer-request'].tcView }}</a>
              </td>
              <td>
                <a href="#" @click.prevent="edit(item)" v-if="showEdit(item)">
                  <b-icon-pencil-square color="#32C5FF" scale="1.2"></b-icon-pencil-square>
                </a>
                <a href="#" class="delete-icon" @click.prevent="deleteItem(item)" v-if="showDelete(item)">
                  <b-icon-x variant="danger" scale="2"></b-icon-x>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- / card content table -->
    <div class="bottom-buttons" v-if="!isToggled">
      <b-button variant="btn btn-light" @click="toggleExpiredRequests()">{{ toggleExpiredRequestText }}</b-button>
      <b-button variant="btn btn-light" @click="openManageCalendar()">{{
        i18n['prayer-request'].tcManagePrayerCalendar
      }}</b-button>
    </div>
    <!-- / card bottom buttons -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'PrayerRequest',
  props: {
    items: { type: Array },
    level: { type: String },
    name: { type: String },
    i18n: {
      type: Object,
      default: () => {
        return {
          'prayer-request': {
            tcAddPrayerRequest: 'Add Prayer Request',
            tcCancel: 'Cancel',
            tcHideExpiredRequestText: 'Hide Expired Requests',
            tcToggleExpiredRequestText: 'View Expired Requests',
            tcManagePrayerCalendar: 'Manage Prayer Calendar',
            tcOk: 'Ok',
          },
        }
      },
    },
  },
  data() {
    return {
      isToggled: true,
      isExpiredToggled: true,
      showExpiredRequest: false,
      fields: ['Request', 'Type', 'Active', 'Edit/Delete'],
    }
  },
  methods: {
    ...mapActions({
      setItem: 'prayerCenter/setPrayerRequestItem',
      setNewPrayerRequestObject: 'prayerCenter/setNewPrayerRequestObject',
    }),
    showDelete(item) {
      return true
    },
    showDate(date) {
      date = !date ? new Date() : date.replace(' ', 'T')
      return new Date(date).toLocaleString(this.prefCulture, { year: 'numeric', day: '2-digit', month: '2-digit' })
    },
    showEdit(item) {
      return true
    },
    openManageCalendar() {
      this.$router.push({
        name: 'prayer-add-edit-calendar',
      })
    },
    toggleExpiredRequests() {
      this.showExpiredRequest = !this.showExpiredRequest
      this.isExpiredToggled = this.showExpiredRequest
      this.$emit('toggleExpired', this.showExpiredRequest)
    },
    async edit(item) {
      await this.setNewPrayerRequestObject({
        item: item,
        level: this.level,
        prayerKey: item.cid_key,
        calendars: this.calendars,
      })
      this.$router.push({
        name: 'prayer-edit-request',
      })
    },
    async add() {
      await this.setNewPrayerRequestObject({
        level: this.level,
        prayerKey: this.defaultKey,
        calendars: this.calendars,
      })
      this.$router.push({
        name: 'prayer-edit-request',
      })
    },
    open(item) {
      let prayerRequestItem = item
      prayerRequestItem.level = this.level
      this.setItem(prayerRequestItem)
      this.$router.push({
        name: 'prayer-request-details',
      })
    },
    async deleteItem(item) {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: `${item.cid_request_title} ${this.i18n['prayer-request'].tcWillBeDeleted}`,
        confirmButtonText: this.i18n['prayer-request'].tcOk,
        cancelButtonText: this.i18n['prayer-request'].tcCancel,
      }).then((result) => {
        if (result.value) {
          item.level = this.level
          this.$emit('delete_approved', item)
        }
      })
    },
    toggle() {
      this.isToggled = !this.isToggled
    },
  },
  computed: {
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
    }),
    itemValues() {
      let itemValues = []
      if (this.items.length > 0) {
        this.items
          .filter((item) => Object.keys(item.request).length > 0)
          .map((item) => {
            itemValues = [...itemValues, ...item.request.special]
          })
      }
      if (this.showExpiredRequest) {
        return itemValues.filter((item) => new Date(item.cid_end_date) < new Date())
      }
      return itemValues
    },
    toggleExpiredRequestText() {
      return this.showExpiredRequest
        ? this.i18n['prayer-request'].tcHideExpiredRequestText
        : this.i18n['prayer-request'].tcToggleExpiredRequestText
    },
    calendars() {
      return this.items.map((item) => {
        return {
          text: item.name,
          value: item.cprKey,
        }
      })
    },
    defaultKey() {
      if (this.items.length == 1) {
        return this.items[0].cprKey
      }
      return null
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/PrayerCalendar.scss';
.third-field {
  padding-right: 20px !important;
  padding-bottom: 30px !important;
}
.first-field {
  width: 30% !important;
}
.delete-icon {
  margin-left: 15px;
}
</style>
