<template>
  <div class="church-list template-1">
    <page-header class="mx-lg">
      <h3 class="header-title">{{ this.translations.tcManageSpecialRequests }}</h3>
    </page-header>
    <div v-for="(req, ix) in prayerRequestDisplayArray" :key="ix">
      <PrayerRequest
        :name="req.name"
        :items="req.items"
        :level="req.level"
        :i18n="translations.components"
        @delete_approved="deleteItem($event, ix)"
        @toggleExpired="toggleExpired($event)"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import pageHeader from '@/components/page-components/PageHeader.vue'
import PrayerRequest from '@/components/PrayerRequest.vue'
import { securityMixin } from '@/mixins/securityMixin'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'prayer-add-edit-request',
  mixins: [securityMixin, translationMixin],
  components: { PrayerRequest, pageHeader: pageHeader },
  data: () => ({
    translations: {},
    prayerRequestArray: [],
    prayerRequestActiveArray: [],
    prayerRequestDisplayArray: [],
    campLevel: 'camp',
    countryLevel: 'country',
    stateLevel: 'state',
    international: 'headquarters',
    secured_controls: {
      country_prayer_calendar_manage_request_panel: 'cf9cb55c-87aa-4b33-8b41-8205b2a94236',
      camp_prayer_calendar_manage_request_panel: 'b02e7c3a-b2cc-4ba1-a821-5c091eb83b0e',
      state_prayer_calendar_manage_request_panel: '20f3c818-cc4e-4540-884c-7e9082439e87',
      hq_prayer_calendar_manage_request_panel: '1e6ede90-c9d0-48e0-9ef9-75a5c0f21836',
    },
    country_panel_accessible: false,
    camp_panel_accessible: false,
    state_panel_accessible: false,
    hq_panel_accessible: false,
  }),
  methods: {
    ...mapActions({
      deletePrayerRequest: 'prayerCenter/deletePrayerRequest',
      getCampSpecialRequest: 'prayerCenter/getCampSpecialRequest',
      getControlPermissionsFromList: 'user/getControlPermissionsFromList',
      getCountrySpecialRequest: 'prayerCenter/getCountrySpecialRequest',
      getInternationalSpecialRequest: 'prayerCenter/getInternationalSpecialRequest',
      getStateSpecialRequest: 'prayerCenter/getStateSpecialRequest',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async pageLoad() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([await this.checkPermissions(), await this.performAPICalls(), await this.filterOutExpired()])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async filterOutExpired() {
      let i_array = this.getIndexes(new Date())
      this.prayerRequestActiveArray = await JSON.parse(JSON.stringify(this.prayerRequestArray))
      i_array.forEach((ia) => {
        if (!!this.prayerRequestActiveArray[ia.a].items[ia.b]) {
          this.prayerRequestActiveArray[ia.a].items[ia.b].request.special.splice(ia.c, 1)
        }
      })
      this.prayerRequestDisplayArray = await JSON.parse(JSON.stringify(this.prayerRequestActiveArray))
    },
    getIndexes(today_date) {
      let index_array = []
      this.prayerRequestArray.forEach((pf, ax) => {
        pf.items.forEach((pim, bx) => {
          if (!!pim.request && !!pim.request.special && pim.request.special.length > 0) {
            pim.request.special.forEach((rs, cx) => {
              if (new Date(rs.cid_end_date).valueOf() < today_date.valueOf()) {
                index_array.push({ a: ax, b: bx, c: cx })
              }
            })
          }
        })
      })
      return index_array.sort((a, b) => {
        if (b.a > a.a) return 1
        if (a.a > b.a) return -1
        if (b.b > a.b) return 1
        if (a.b > b.b) return -1
        if (b.c > a.c) return 1
        if (a.c > b.c) return -1
      })
    },
    async toggleExpired(evt) {
      this.prayerRequestDisplayArray = await JSON.parse(
        JSON.stringify(evt ? this.prayerRequestArray : this.prayerRequestActiveArray)
      )
    },
    async performAPICalls() {
      await this.secured_camp_api_call()
      await this.secured_state_api_call()
      await this.secured_country_api_call()
      await this.secured_hq_api_call()
    },
    async checkPermissions() {
      await this.getControlPermissionsFromList(Object.values(this.secured_controls)).then(() => {
        this.state_panel_accessible = this.determineAccessibility(
          this.secured_controls.state_prayer_calendar_manage_request_panel
        )
        this.country_panel_accessible = this.determineAccessibility(
          this.secured_controls.country_prayer_calendar_manage_request_panel
        )
        this.camp_panel_accessible = this.determineAccessibility(
          this.secured_controls.camp_prayer_calendar_manage_request_panel
        )
        this.hq_panel_accessible =
          this.determineAccessibility(this.secured_controls.hq_prayer_calendar_manage_request_panel) ||
          this.userId === 'a538d613-8d4f-4056-87eb-13e459420399'
      })
    },
    async secured_hq_api_call() {
      if (this.hq_panel_accessible) {
        await this.getInternationalSpecialRequest()
        this.prayerRequestArray.push({
          name: this.translations.tcHeadquarters,
          items: this.internationalSpecialRequest,
          level: this.international,
        })
      }
    },
    async secured_country_api_call() {
      if (this.country_panel_accessible) {
        if (this.userCountryKey !== constantData.USA_country) {
          await this.getCountrySpecialRequest()
          this.prayerRequestArray.push({
            name: this.translations.tcCountryPayerRequest,
            items: this.countrySpecialRequest,
            level: this.countryLevel,
          })
        }
      }
    },
    async secured_state_api_call() {
      if (this.state_panel_accessible) {
        if (this.userCountryKey === constantData.USA_country) {
          await this.getStateSpecialRequest()
          this.prayerRequestArray.push({
            name: this.translations.tcStatePrayerRequest,
            items: this.stateSpecialRequest,
            level: this.stateLevel,
          })
        }
      }
    },
    async secured_camp_api_call() {
      if (this.camp_panel_accessible) {
        await this.getCampSpecialRequest()
        this.prayerRequestArray.push({
          name: this.translations.tcCampPrayerRequest,
          items: this.campSpecialRequest,
          level: this.campLevel,
        })
      }
    },
    async removeDeletedItem(item, ix) {
      let fi = this.prayerRequestArray[ix].items
        .find((ab) => ab.active === true)
        .request.special.findIndex((bc) => bc.cid_key === item.cid_key)
      this.prayerRequestArray[ix].items.find((ab) => ab.active === true).request.special.splice(fi, 1)
    },
    async deleteItem(item, ix) {
      try {
        this.setLoadingStatus(true)
        await this.deletePrayerRequest(item.cid_key).then((res) => {
          if (res) {
            this.removeDeletedItem(item, ix).then(() => {
              this.$swal({
                icon: 'success',
                text: this.translations.tcRequestDeletedSuccessfully,
                confirmButtonText: this.translations.tcOk || 'Ok',
              })
            })
          } else {
            this.$swal({
              icon: 'error',
              text: this.translations.tcErrorDeletingRequest,
              confirmButtonText: this.translations.tcOk || 'Ok',
            })
          }
        })
        this.pageLoad()
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
  },
  async created() {
    this.setLoadingStatus(true)
    await Promise.all([
      await this.getViewTranslations(),
      await this.getComponentTranslations('prayer-request'),
      await this.pageLoad(),
    ]).then((results) => {
      const componentTranslations = results[1]
      this.$set(this.translations, 'components', componentTranslations)
    })
  },
  computed: {
    ...mapGetters({
      campSpecialRequest: 'prayerCenter/campSpecialRequest',
      countrySpecialRequest: 'prayerCenter/countrySpecialRequest',
      internationalSpecialRequest: 'prayerCenter/internationalSpecialRequest',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      stateSpecialRequest: 'prayerCenter/stateSpecialRequest',
      userCountryKey: 'user/userCountryKey',
      userId: 'user/userId',
      userLogin: 'user/userLogin',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchProfile.scss';
@import '@/styles/bootstrap/b-button.scss';

h3.header-title {
  font-family: din\1451 W01 Engschrift, sans-serif;
  font-size: 40px;
  letter-spacing: 1.2px;
  line-height: 27px;
  margin-left: 30px;
  text-transform: uppercase;
}
</style>
